import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./services/auth";
import Providers from "./services/providers";

import App from "./App";
import Dashboard from "./Dashboard";
import Login from "./views/Login";
import ForgotPass from "./views/ForgotPass";
import SetPass from "./views/User/SetPass";
import profileRoutes from "./views/User/Routes";
import manufacturerAppsRoutes from './views/manufacturerApps/routes';
import Releases from "./views/releases/Releases";

import NoAccess from "./views/NoAccess";
import TagMetaData from "./views/tagMetaData/TagMetaData";
// import Certificate from "./views/manufacturers/Certificate";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Providers>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          {profileRoutes}
          {manufacturerAppsRoutes}
          {/* {manufacturer} */}
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPass />} />
          <Route path="set" element={<SetPass />} />
          <Route path="sdk/:platform" element={<Releases />} />
          <Route path="tag-meta-data" element={<TagMetaData />} />
          {/* <Route path="certificate" element={<Certificate />} /> */}
          <Route path="no-access" element={<NoAccess />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
