import { Pagination, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRequest } from "../../services/request";
import ReleasesRow from "./ReleasesRow";
import SearchInput from "../../ui/SearchInput";
import { Box } from "@mui/system";
import { el } from "date-fns/locale";

const PAGE_SIZE = 20;

function Releases() {
  const [releases, setReleases] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState();
  const { platform } = useParams();
  const req = useRequest();

  const handleChangePage = (event, newPage) => setPage(newPage);

  useEffect(() => {
    (async (platform = "android", keyword = null, page = 0, pageSize = 20) => {
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;

      if (platform === "android") query += `&platform=1`;
      else if (platform === "ios") query += `&platform=2`;
      else query += `&platform=3`;

      const resData = await req(`releases/?${query}`, null, {}, true);
      setReleases(resData.results);
      setCount(resData.count);
    })(platform, term, page, PAGE_SIZE);
  }, [term, req, page, platform]);

  if (!releases) return null;

  return (
    <div id="releases-list">
      <Typography variant="h4" sx={{ my: 4 }}>
        {platform.toUpperCase()} SDK releases
      </Typography>

      <Box sx={{ maxWidth: 700 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box sx={{ flex: "grow" }} />
          <SearchInput setTerm={setTerm} />
        </Box>
        {releases.length ? (
          releases.map((r) => <ReleasesRow key={r.id} row={r} />)
        ) : (
          <Paper variant="outlined">
            <Typography variant="body2" sx={{ textAlign: "center", py: 10 }}>
              No results found.
            </Typography>
          </Paper>
        )}
      </Box>

      {count > PAGE_SIZE && (
        <Pagination
          data-testid="releases-pagination"
          count={count}
          page={page}
          onChange={handleChangePage}
          size="small"
        />
      )}
    </div>
  );
}

export default Releases;
